<template>
  <v-container
    fluid
    class="pa-0 snow-white"
  >
    <div
      class="sec5-dsk"
      style="margin-block: 100px"
    >
      <div class="sec5-cnt">
        <v-row>
          <v-col class="sec5-title">
            {{ $t("app.page.heading") }}
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col>
            <v-btn
              class="notransform"
              large
              color="#A4C639"
              @click="install"
            >
              <v-icon color="white">
                mdi-android
              </v-icon>
              Android
            </v-btn>
            <v-btn
              class="notransform"
              large
              color="#555555"
              @click="installIos"
            >
              <v-icon>mdi-apple</v-icon>
              iOS
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-img
        src="/img/dashboard/mobile-app-mockup.png"
        :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
      ></v-img>
    </div>
    <div
      v-if="showIosGuide"
      ref="iosGuide"
      class="iosGuide"
    >
      <v-row class="d-flex justify-center">
        <v-col
          sm="10"
          lg="10"
        >
          <h2>{{ $t("app.page.title") }}</h2>
          <p
            v-for="(step,index) in $t('app.page.steps')"
            :key="index"
          >
            {{ step }}
          </p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          lg="10"
          sm="10"
        >
          <ol>
            <li
              v-for="(step,index) in $t('app.steps')"
              :key="index"
            >
              {{ step }}
            </li>
          </ol>
        </v-col>
      </v-row>
      <v-row class="flex justify-center">
        <v-col
          lg="10"
          sm="10"
        >
          <video
            height="480"
            autoplay
            src="https://web.dev/static/learn/pwa/installation/video/RK2djpBgopg9kzCyJbUSjhEGmnw1/UhWxRAtIB6KQpbMYnDSe.mp4"
          ></video>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

  export default {
    data () {
      return {
        showIosGuide: false,
        deferredPrompt: null,

      }
    },
    computed: {
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xlOnly () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smOnly () {
        return this.$vuetify.breakpoint.smOnly
      },
      mdOnly () {
        return this.$vuetify.breakpoint.mdOnly
      },
      lgOnly () {
        return this.$vuetify.breakpoint.lgOnly
      },
    },
    created () {

    },

    methods: {
      async install () {
        this.$root.showInstallPrompt()
      },
      async installIos () {
        this.showIosGuide = true
        this.$refs.iosGuide.scrollIntoView()
      },
    }
  }
</script>

<style scoped>
.iosGuide {
  margin-block-end: 80px;
}

.mdi-apple::before {
  content: "\F0035";
  position: relative;
  top: -2px;
  right: 10px;
}

.mdi-android::before {
  content: "\F0032";
  position: relative;
  top: -1px;
  right: 10px;
}

.notransform {
  text-transform: unset;
}

.iosGuide ol {
  clear: both;
}

@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }

  .bw-dashboard {
    height: 100vh;
  }
}

.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.1);
}

.section1-desktop {
  position: relative;
  display: flex;
  padding-block: 50px;
  padding-inline: 9%;
  height: 600px;
  background-image: url("/img/blanketmain-v1.jpg");
  background-position: center;
  background-size: cover;
}

.section1-xl {
  position: relative;
  display: flex;
  padding-block: 50px;
  padding-inline: 9%;
  height: 800px;
  background-image: url("/img/blanketmain-v1.jpg");
  background-position: center;
  background-size: cover;
}

.section1-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: flex-end;
}

.apply-btn-txt-hero {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  align-self: flex-start;
  line-height: 125%;
  letter-spacing: 0.00892857em;
  color: #ffffff;
}

.apply-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 125%;
  letter-spacing: 0.00892857em;
  color: #292f36 !important;
  align-self: flex-start;
}

.section2-desktop {
  position: relative;
  height: 831.86px;
  background: #ffffff;
}

.sec2-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.sec2-dsk-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
  letter-spacing: -0.424019px;
  /* Color Palette/Dark Grey */
  color: #292f36;
}

.sec2-dsk-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  /* Color Palette/Dark Grey */
  color: #292f36;
}

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 24px;
  gap: 10px;
  background: #ffffff;
  /* Color Palette/CTA Color */
  height: 242px;
  border: 1px solid #aaf0c1;
  /* Box Shadow Light */
  box-shadow: 0px 12px 32px rgba(50, 0, 79, 0.1);
  border-radius: 8px;
}

.prd-card-title {
  position: absolute;
  top: 137px;
  left: 0;
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 110%;
  /* identical to box height, or 33px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Color Palette/Pacific Blue */
  color: #00a1b7;
}

.product-tag {
  position: absolute;
  /* top: 175px; */
  bottom: -5px;
  left: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  /* font-size: 16px; */
  font-size: 14.5px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}

@media screen and (max-width: 360px) {
  .product-tag {
    font-size: 12px;
  }
}

.lrn-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16.5px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  /* midnight eagle green */
  color: #005f6c;
}

.learn-btn {
  width: 137px;
  height: 33px;
  border: 1.37127px solid #005f6c;
  border-radius: 5.48507px;
  background: #ffffff !important;
}

.sec3-dsk {
  height: 1005px;
  background: url("/img/secondary-knit.jpg");
}

.sec3-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #ffffff;
}

.dash-icons {
  font-size: 48px !important;
}

.sec3-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* or 114% */
  text-align: center;
  /* midnight eagle green */
  color: #ffffff;
}

.sec3-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* gunmetal */
  color: #ffffff;
}

.sec5-dsk {
  position: relative;
  height: 356px;
  background: url("/img/dash-pattern.jpg");
}

.sec5-dsk-phone {
  position: absolute;
  left: 726px;
  top: -78px;
}

.sec5-xl-phone {
  position: absolute;
  left: 1100px;
  top: -78px;
}

.sec5-cnt {
  position: absolute;
  width: 516px;
  height: 157px;
  left: 794px;
  top: 75px;
  left: 214px;
}

.sec5-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  /* or 46px */
  letter-spacing: -0.424019px;
  /* Color Palette/White (100%) */
  color: #ffffff;
}

.sec5-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Color Palette/White (100%) */
  color: #ffffff;
}

.sec6-dsk {
  height: 673px;
  background: #ffffff;
}

.faq-div {
  margin-top: 40px;
  width: 928px;
  height: 407px;
}

.faq-titles {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

::v-deep .v-expansion-panel {
  border-bottom: 2px solid #aaf0c1 !important;
}

::v-deep .v-expansion-panel-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

::v-deep .v-expansion-panel::before {
  content: none;
}

.sec7-dsk {
  position: relative;
  height: 570px;
  background: url("/img/partner-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.about-frame {
  position: absolute;
  width: 580px;
  height: 370px;
  left: 130px;
  top: 86px;
}

.about-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.generali-logo {
  position: absolute;
  width: 231.7px;
  height: 31.25px;
  left: 641px;
  top: 77px;
}

.stillwater-logo {
  position: absolute;
  width: 252.18px;
  height: 128.24px;
  left: 927.64px;
  top: 0;
}

.trupanion-logo {
  position: absolute;
  width: 232.05px;
  height: 69.27px;
  left: 641px;
  top: 170px;
  display: none;
}

.afficiency-logo {
  position: absolute;
  width: 139.36px;
  height: 52.76px;
  left: 927.64px;
  top: 140px;
}

.tugo-logo {
  position: absolute;
  width: 154px;
  height: 68.96px;
  left: 641px;
  top: 141px;
}

.humania-logo {
  position: absolute;
  width: 154px;
  height: 68.96px;
  left: 641px;
  top: 221px;
  width: 245px;
}

.auto-chip {
  position: absolute;
  left: 140px;
  top: 8%;
  height: 75px;
  width: 157px;
}

.travel-chip {
  position: absolute;
  right: 10.7%;
  top: 8%;
  height: 75px;
  width: 115px;
}

.life-chip {
  position: absolute;
  left: 41.7%;
  top: 10%;
  height: 75px;
  width: 130px;
}

.pet-chip {
  position: absolute;
  right: 42%;
  top: 23.3%;
  height: 90px;
  width: 75px;
}

.chip:hover {
  cursor: pointer;
}

.coming-btn {
  width: auto;
  height: 33px;
  border: 1.51871px solid rgba(0, 0, 0, 0.26);
  border-radius: 5.48507px;
  background: #ffffff !important;
}

.coming-btn:hover {
  cursor: default !important;
}

.coming-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16.5px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.26);
}

.inactive {
  cursor: default !important;
}

.news-section {
  padding: 5rem 4rem 7rem 4rem;
}

.news-section h1 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.news-section h1.news-title {
  color: #005f6c;
}

.news-section p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
  margin: 1rem 0rem;
}

/*----------------------------TABLET---------------------------*/
.section2-tablet {
  position: relative;
  background: #ffffff;
}

.sec2-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.sec2-dsk-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Color Palette/Dark Grey */
  color: #292f36;
}

.sec2-dsk-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 24px;
  gap: 10px;
  background: #ffffff;
  /* Color Palette/CTA Color */
  height: 242px;
  border: 1px solid #aaf0c1;
  /* Box Shadow Light */
  box-shadow: 0px 12px 32px rgba(50, 0, 79, 0.1);
  border-radius: 8px;
}

.prd-card-title {
  position: absolute;
  top: 137px;
  left: 0;
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 110%;
  /* identical to box height, or 33px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Color Palette/Pacific Blue */
  color: #00a1b7;
}

/* .product-tag {
position: absolute;
top: 175px;
left: 0;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
} */
.lrn-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  /* midnight eagle green */
  color: #005f6c;
}

.learn-btn {
  width: 137px;
  height: 33px;
  border: 1.37127px solid #005f6c;
  border-radius: 5.48507px;
  background: #ffffff !important;
}

.sec3-tablet {
  background-image: url("/img/secondary-knit.jpg");
  background-position: center;
  background-size: cover;
}

.sec3-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #ffffff;
}

.dash-icons {
  font-size: 48px !important;
}

.sec3-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* or 114% */
  text-align: center;
  /* midnight eagle green */
  color: #ffffff;
}

.sec3-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* gunmetal */
  color: #ffffff;
}

.sec4-tablet {
  background: #ffffff;
}

.sec4-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  /* or 46px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Dark Green */
  color: #005f6c;
}

.sec4-dsk-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.sec4-icon {
  font-size: 36px !important;
}

.sec4-qt {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}

.sec4-qt-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  color: #292f36;
}

.sec5-tablet {
  background-image: url("/img/dash-pattern.jpg");
  background-position: center;
  background-size: cover;
}

.sec5-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #ffffff;
}

.sec5-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Color Palette/White (100%) */
  color: #ffffff;
}

.sec6-dsk {
  height: 673px;
  background: #ffffff;
}

.faq-div {
  margin-top: 40px;
  width: 928px;
  height: 407px;
}

.faq-titles {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

::v-deep .v-expansion-panel {
  border-bottom: 2px solid #aaf0c1 !important;
}

::v-deep .v-expansion-panel-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

::v-deep .v-expansion-panel::before {
  content: none;
}

.sec7-tablet {
  background-image: url("/img/partner-background.jpg");
  background-position: center;
  background-size: cover;
}

.about-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.generali-logo-tablet {
  width: 231.7px;
  height: 31.25px;
}

.stillwater-logo-tablet {
  width: 252.18px;
  height: 128.24px;
}

.trupanion-logo-tablet {
  width: 232.05px;
  height: 69.27px;
  display: none;
}

.afficiency-logo-tablet {
  width: 139.36px;
  height: 52.76px;
}

.tugo-logo-tablet {
  width: 154px;
  height: 68.96px;
}

.humania-logo-tablet {
  width: 265px;
  height: 68.96px;
}

.auto-chip {
  height: 75px;
  width: 157px;
}

.travel-chip {
  position: absolute;
  right: 10.7%;
  top: 8%;
  height: 75px;
  width: 115px;
}

.life-chip {
  position: absolute;
  left: 41.7%;
  top: 10%;
  height: 75px;
  width: 130px;
}

.pet-chip {
  position: absolute;
  right: 42%;
  top: 23.3%;
  height: 90px;
  width: 75px;
}

.chip:hover {
  cursor: pointer;
}

.coming-btn {
  width: 137px;
  height: 33px;
  border: 1.51871px solid rgba(0, 0, 0, 0.26);
  border-radius: 5.48507px;
  background: #ffffff !important;
  font-size: 12px;
}

.coming-btn:hover {
  cursor: default !important;
}

.coming-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.26);
}

.inactive {
  cursor: default !important;
}

.news-section-tablet {
  padding: 3rem 4rem 5rem 3rem;
}

.news-section-tablet h1 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.news-section-tablet h1.news-title {
  color: #005f6c;
  font-size: 25px;
}

.news-section-tablet p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 2.3vw;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
  margin: 1rem 0rem;
}

/*---------------------------MOBILE---------------------------*/
.section1-mobile {
  position: relative;
  height: 375px;
  background-image: url("/img/blanketmain-v1.jpg");
  background-position: center;
  background-size: cover;
}

.section1-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #ffffff;
}

.sec3-mobile {
  background-image: url("/img/secondary-knit-mobile.jpg");
  background-position: center;
  background-size: cover;
}

.sec5-mobile {
  background-image: url("/img/dash-pattern-mobile.jpg");
  background-position: center;
  background-size: cover;
}

.sec7-mobile {
  background-image: url("/img/partner-background-mobile.jpg");
  background-position: center;
  background-size: cover;
}

.news-section-mobile {
  padding: 2rem 0rem 3rem 0rem;
}

.news-section-mobile h1 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.news-section-mobile h1.news-title {
  color: #005f6c;
  font-size: 25px;
}

.news-section-mobile p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-weight: 300;
  font-size: 19px;
  line-height: 150%;
  color: #292f36;
  margin: 1rem 0rem;
}

video {
  width: 100%;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  video {
    width: 320px;
    height: 100%;
  }

  .sec5-cnt {
    display: grid;
    place-content: center;
    place-items: center;
    text-align: center;
    height: 355px;
    gap: 0;
    position: absolute;
    width: unset !important;
    height: unset !important;
    left: unset !important;
    left: unset !important;
  }

  .iosGuide {
    padding-inline: 32px;
  }

  .coming-btn,
  .learn-btn {
    width: 180px;
  }

  .coming-txt,
  .learn-btn,
  .lrn-btn-txt {
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .coming-btn,
  .learn-btn {
    width: auto !important;
    max-width: 235px !important;
  }

  .coming-txt,
  .learn-btn {
    font-size: 15px;
  }

  .learn-btn {
    width: auto !important;
    max-width: 235px !important;
  }

  @media screen and (821px <= width <= 1024px) {
    .sec5-dsk-phone {
      left: 460px !important;
    }

    .sec5-cnt {
      left: 100px !important;
    }

    video {
    }
  }
  @media screen and (768px <= width <= 820px) {
    .sec5-dsk-phone {
      display: none;
    }

    .sec5-cnt {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: unset;
      width: unset;
    }
  }

}
</style>
